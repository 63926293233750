import { PLAYER_INITIAL_STATE } from './constants'

export default (state = PLAYER_INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case 'set_tracks': {
      return { ...state, tracks: action.payload }
    }
    case 'set_playing_state': {
      return { ...state, playingState: action.payload }
    }
    case 'set_volume': {
      return { ...state, volume: action.payload }
    }
    case 'set_elapsed': {
      return { ...state, ...{ ...action.payload } }
    }
    case 'play_track': {
      return { ...state, ...{ ...action.payload } }
    }
    case 'play_next': {
      return { ...state, ...{ ...action.payload } }
    }
    case 'play_prev': {
      return { ...state, ...{ ...action.payload } }
    }

    default:
      return { ...state }
  }
}
