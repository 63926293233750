// Helpers, Config, Constants, Reducers
import createDataContext from './createDataContext'
import microcopyReducer from './microcopyReducer'
import { MICROCOPY_INITIAL_STATE } from './constants'

export const setMicrocopy = (dispatch) => (value) => {
  dispatch({ type: 'set_microcopy', payload: value })
}

export const { Context, Provider } = createDataContext(
  microcopyReducer,
  {
    setMicrocopy,
  },
  MICROCOPY_INITIAL_STATE
)
