// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-agenda-index-js": () => import("./../../../src/templates/agenda/index.js" /* webpackChunkName: "component---src-templates-agenda-index-js" */),
  "component---src-templates-album-index-js": () => import("./../../../src/templates/album/index.js" /* webpackChunkName: "component---src-templates-album-index-js" */),
  "component---src-templates-bio-index-js": () => import("./../../../src/templates/bio/index.js" /* webpackChunkName: "component---src-templates-bio-index-js" */),
  "component---src-templates-contato-index-js": () => import("./../../../src/templates/contato/index.js" /* webpackChunkName: "component---src-templates-contato-index-js" */),
  "component---src-templates-fotos-index-js": () => import("./../../../src/templates/fotos/index.js" /* webpackChunkName: "component---src-templates-fotos-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-musicas-index-js": () => import("./../../../src/templates/musicas/index.js" /* webpackChunkName: "component---src-templates-musicas-index-js" */),
  "component---src-templates-videos-index-js": () => import("./../../../src/templates/videos/index.js" /* webpackChunkName: "component---src-templates-videos-index-js" */)
}

