// Dependencies
const isBrowser = typeof window !== 'undefined'
const soundManager = isBrowser ? require('soundmanager2').soundManager : null

// Helpers, Config, Constants, Reducers
import createDataContext from './createDataContext'
import playerReducer from './playerReducer'
import { PLAYER_INITIAL_STATE } from './constants'

const loadSoundManager = (albums, dispatch) => {
  // Checking if soundManager library is fully loaded
  soundManager.onready(() => {
    // iterating through the tracks and creating the sound objects
    Object.keys(albums).forEach((albumId, index) => {
      albums[albumId].forEach((track) => {
        const soundObjectConfig = {
          id: `track_${track.albumId}_${track.trackId}`,
          url: track.streamUrl,
          usePeakData: false,
          useWaveformData: false,
          useEQData: true,
          usePolicyFile: false,
          onplay: () => {
            dispatch({ type: 'set_playing_state', payload: 'playing' })
          },
          onresume: () => {
            dispatch({ type: 'set_playing_state', payload: 'playing' })
          },
          onpause: () => {
            dispatch({ type: 'set_playing_state', payload: 'paused' })
          },
          onfinish: () => {
            let nextId = track.trackId + 1
            let nextAlbumId = track.albumId
            if (nextId >= albums[track.albumId].length) {
              nextId = 0
              nextAlbumId = track.albumId + 1

              if (nextAlbumId >= Object.keys(albums).length) {
                nextAlbumId = 0
              }
            }

            soundManager.stopAll()
            soundManager.play(`track_${nextAlbumId}_${nextId}`)
            dispatch({
              type: 'play_next',
              payload: {
                currentAlbumId: nextAlbumId,
                currentTrack: albums[nextAlbumId][nextId],
                currentTrackId: nextId,
              },
            })
          },
          whileplaying() {
            // Updating track bar, time elapsed, etc
            let pct = (this.position * 100) / this.duration
            if (pct > 100) {
              pct = 100
            }
            if (pct < 0) {
              pct = 0
            }

            dispatch({
              type: 'set_elapsed',
              payload: {
                elapsedPct: pct,
                elapsedTime: this.position,
                currentTrackDuration: this.duration,
              },
            })
          },
        }
        soundManager.createSound(soundObjectConfig)
      })
    })
    setVolume(PLAYER_INITIAL_STATE.volume)
  })
}

export const setTracks = (dispatch) => (tracks) => {
  loadSoundManager(tracks, dispatch)
  dispatch({ type: 'set_tracks', payload: tracks })
}

export const playTrack = (dispatch) => (track) => {
  soundManager.stopAll()
  soundManager.play(`track_${track.currentAlbumId}_${track.currentTrackId}`)
  dispatch({ type: 'play_track', payload: track })
}

export const pauseTrack = (dispatch) => (track) => {
  soundManager.togglePause(
    `track_${track.currentAlbumId}_${track.currentTrackId}`
  )
}

export const seekPosition = () => (track) => {
  soundManager.setPosition(
    `track_${track.currentAlbumId}_${track.currentTrackId}`,
    track.position
  )
}

export const setVolume = (dispatch) => (volume) => {
  soundManager.setVolume(volume)
  dispatch({ type: 'set_volume', payload: volume })
}

export const playNext = (dispatch) => (nextTrack) => {
  soundManager.stopAll()
  soundManager.play(
    `track_${nextTrack.currentAlbumId}_${nextTrack.currentTrackId}`
  )
  dispatch({ type: 'play_next', payload: nextTrack })
}

export const playPrev = (dispatch) => (prevTrack) => {
  soundManager.stopAll()
  soundManager.play(
    `track_${prevTrack.currentAlbumId}_${prevTrack.currentTrackId}`
  )
  dispatch({ type: 'play_prev', payload: prevTrack })
}

export const { Context, Provider } = createDataContext(
  playerReducer,
  {
    setTracks,
    seekPosition,
    playTrack,
    pauseTrack,
    setVolume,
    playNext,
    playPrev,
  },
  PLAYER_INITIAL_STATE
)
