// Dependencies
import React from 'react'

// Context
import { Provider as PlayerProvider } from '../context/playerContext'
import { Provider as MicrocopyProvider } from '../context/microcopyContext'

export default ({ children }) => (
  <PlayerProvider>
    <MicrocopyProvider>
      {children}
    </MicrocopyProvider>
  </PlayerProvider>
)
