export const PLAYER_INITIAL_STATE = {
  tracks: undefined,
  currentAlbumId: undefined,
  playingState: 'stopped',
  volume: 50, // 50
  currentTrack: undefined,
  currentTrackId: undefined,
  currentTrackDuration: undefined,
  currentAlbumId: undefined,
  elapsedPct: null,
  elapsedTime: null,
}

export const MICROCOPY_INITIAL_STATE = { value: {} }
