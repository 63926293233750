import { MICROCOPY_INITIAL_STATE } from './constants'

export default (state = MICROCOPY_INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case 'set_microcopy': {
      return { ...state, value: action.payload }
    }

    default:
      return { ...state }
  }
}
